//use next.js for the actual website.
//this is for the email confirmation only
//https://www.theracebase.com
//npm start
//http://localhost:3000
//this needs to be typescript...    npx create-react-app realm-web-react-quickstart --template=typescript or for next.js    npx create-next-app@latest

//vercel to upload to web...

import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import './App.css';
import racebaselogo from './racebaselogo_symbol.png';
import racebaseText from './racebaselogo_text.png';
import * as Realm from "realm-web";
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';

const appId = process.env.REACT_APP_PROJECT_ID;
const app = new Realm.App({ id: appId });

const params = new URLSearchParams(window.location.search);
const token = params.get('token');
const tokenId = params.get('tokenId');
const email = params.get('email');

// function UserDetail({ user }) {
//   return (
//     <div>
//       <h1>Logged in with anonymous id: {user.id}</h1>
//     </div>
//   );
// }

// Create a component that lets an anonymous user log in
// function Login({ setUser }) {     //this logs a user in so we are connected to the backend...
//   const loginAnonymous = async () => {
//     const user = await app.logIn(Realm.Credentials.anonymous());
//     setUser(user);
//   };
//   return <button onClick={loginAnonymous}>Log In</button>;
// }

// const [user, setUser] = React.useState(app.currentUser);
// {user ? <UserDetail user={user} /> : <Login setUser={setUser} />}

// function displayResult(result, err) {
//   const message = document.getElementById("message");
//   if (result === "success") {
//     //message.innerText = "Your email has been verified. You can now close this page. Thank you.";
//     message.innerHTML = `
//       <div style="background-color: rgba(26,162,96,0.2); padding: 7.5px; border-radius: 5px; border: 2px solid rgba(26,162,96,0.2);">
//         <p style="color: white; margin: 0;">Your email has been verified. You can close this page and connect to the app. Thank you.</p>
//       </div>`;
//   } else if (result === "error") {
//     //message.innerText = err;
//     message.innerHTML = `
//       <div style="background-color: rgba(255,59,48,0.1); color: white; padding: 7.5px; border-radius: 5px; border: 2px solid rgba(255,59,48,0.2);">
//         <p style="margin: 0;">${err}</p>
//       </div>`;
//   }
// }

// useEffect(() => {
//   const confirmUserWithEmail = async () => {
//     if (token && tokenId) {         // Get Tokens from the URL
//       try {
//         await app.emailPasswordAuth.confirmUser({ token, tokenId });      // Confirm user's email using the token and tokenId
//         await callRealmFunction();
//         displayResult('success');
//       } catch (error) {
//         console.log('error: ', error)
//         displayResult('error', 'An error occurred while confirming user.');
//       }
//     }
//   };
//   confirmUserWithEmail();     // Call the asynchronous function immediately
// }, []);



//start here to make the message box slightly less tall
//in the mobile app make the 'confirmation required.' (DONE)
// implement auto login when the isConfirmed changes (DONE)
//need to put then into production and then change the url in the template

function App() {

  console.log('app')

  const [loading, setLoading] = useState(true);
  const [confirmationState, setConfirmationState] = useState({status: null, message: null});
  const logoRef = useRef(null)
  const hasLoadedBefore = useRef(false)

  function displayResult(result, error) {
    if (result === "success") {
      setConfirmationState({status: 'success', message: 'Your email has been verified. You can close this page and connect to the app. Thank you.'})
    } else if (result === "error") {
      setConfirmationState({status: 'fail', message: error})
    }
    setTimeout(() => { setLoading(false) }, 2000);
  }

  useLayoutEffect(() => {
    const confirmUserWithEmail = async () => {
      console.log('Confirm Users Email')
      if (token && tokenId) {
        try {
          await app.emailPasswordAuth.confirmUser({ token, tokenId });

          await callRealmFunction();
          displayResult('success');
        } catch (error) {
          displayResult('error', 'An error occurred while confirming user.');
        }
      }
    };

    if(!hasLoadedBefore.current){     //Component loads twice - intentional measure by the React team to ensure your components are pure functions. (this makes sure the useEffect runs only once)
      hasLoadedBefore.current = true;
      confirmUserWithEmail();
    }
  }, []);

  useEffect(()=> { console.log('confirmationState', confirmationState) }, [confirmationState])

  const callRealmFunction = async () => {
    const endpointUrl = `https://europe-west1.gcp.data.mongodb-api.com/app/${appId}/endpoint/confirmUsersEmail`;
    
    const requestData = {
      token: token,
      tokenId: tokenId,
      email: email
    };

    try {
      const response = await axios.post(endpointUrl, requestData, {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Bearer ${tokenId}`,
        }
      });

      //console.log('Response status:', response.status, response.data);

    } catch (error) {
      throw new Error(`${error.message}`)
    }
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img ref={logoRef} src={racebaselogo} className="App-logo" alt="RACEBASE_LOGO" />


        {!loading ? (
          <div className={confirmationState.status === 'success' ? 'success-message' : 'error-message'}>
            <p>{confirmationState.message}</p>
          </div>
        ) : (
          <div className="spinner">
            <PropagateLoader 
              color={'white'} 
              loading={loading} 
              size={10} 
            />
          </div>
        )}
      
      </header>

      <div className="bottom-corner">
        <img src={racebaseText} alt="RACEBASE" className="bottom-corner-img" />
        <p className="bottom-corner-text">2024</p>
      </div>
    </div>
  );
}

export default App;